import "../components/base.css";
import Layout from "../components/layout";
import React from "react";


export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <br></br>
          <h1>&gt; LOAD OUTS</h1>
        </div>
        <br></br>
        <div>
          <h2>GET STARTED SHIP BUILDING /</h2>
            <h3><p>Head on over to the <a rel="noreferrer" target="_blank" href="https://robertsspaceindustries.com/pledge/">Erkul.Games Ship Builder, and go nuts</a></p></h3>
          <br></br>
        </div>
        <div>
          <h2>VANGAURD BUILDS /</h2>
            <h3>Check out <a rel="noreferrer" target="_blank" href="https://www.reddit.com/r/starcitizen/comments/f0jj3q/whats_the_best_loadout_for_the_vanguard_sentinel/">Erebus-Kingsley Spec</a></h3>
          <br></br>
          </div>
      </Layout>
    );
  }
}